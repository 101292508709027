import React, { useState } from 'react';
import { useCart } from './CartContext'; // Import the CartContext
import './Account.css';
import PaystackIntegration from './Paystackintegration'; // Assuming you have a PaystackIntegration component
import OrderConfirm from './OrderConfirm'; // Import the OrderConfirm component

const Account = () => {
  const [activeSection, setActiveSection] = useState('Orders');
  const { cartItems = [], clearCart } = useCart(); // Get cartItems and clearCart from CartContext

  // Calculate the subtotal and total price for the orders
  const subtotal = cartItems.reduce(
    (total, product) => total + product.price * product.quantity,
    0
  );
  const shippingCost = 13000; // Example shipping cost in Naira
  const total = subtotal + shippingCost;

  // Helper function to format prices
  const formatPrice = (price) => Math.round(price);

  // State for shipping inputs
  const [fullName, setFullName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('Lagos');
  const [state, setState] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('Nigeria');

  // Validate shipping form
  const isShippingFormValid = () => {
    return fullName && streetAddress && state && phone && email;
  };

  // Handle Payment Success and Cancel callbacks
  const handlePaymentSuccess = (reference) => {
    console.log('Payment Successful:', reference);
    clearCart();
    setActiveSection('OrderConfirm');
  };

  const handlePaymentCancel = () => {
    console.log('Payment Cancelled');
  };

  // Render content based on the active section
  const renderContent = () => {
    switch (activeSection) {
      case 'Orders':
        return (
          <div className="product-section">
            {cartItems.length === 0 ? (
              <p>No orders placed yet.</p>
            ) : (
              <table className="product-table">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((product) => (
                    <tr key={product.id}>
                      <td className="product-details">
                        <img
                          src={`../assets/products/${product.image}`}
                   />
                        <div>
                          <p className="product-name">{product.name}</p>
                          <p className="product-sku">{product.sku}</p>
                          <p className="product-attributes">
                            Size: {product.size} | Color: {product.color}
                          </p>
                        </div>
                      </td>
                      <td>₦{formatPrice(product.price)}</td>
                      <td className="quantity-control">
                        <span>{product.quantity}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        );
      case 'Shipping':
        return (
          <div className="shipping-section">
            <div className="Ship-Container">
              <h2 className="section-title">
                ENTER SHIPPING & BILLING INFORMATION
              </h2>
              <form className="shipping-form">
                <div className="form-group">
                  <input
                    type="text"
                    id="fullName"
                    required
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="streetAddress"
                    required
                    placeholder="Street Address"
                    value={streetAddress}
                    onChange={(e) => setStreetAddress(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    id="phone"
                    required
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="emailing"
                    required
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input type="text" id="city" required value={city} readOnly />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="state"
                    required
                    placeholder="State"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="country"
                    required
                    value={country}
                    readOnly
                  />
                </div>
              </form>
            </div>
            {cartItems.length > 0 && (
              <div className="Ordersummary-Container">
                <h3>Order Summary</h3>
                <div className="totals">
                  <p>
                    Subtotal: <span>₦{formatPrice(subtotal)}</span>
                  </p>
                  <p>
                    Shipping Cost: <span>₦{formatPrice(shippingCost)}</span>
                  </p>
                  <p className="total">
                    Total: <span>₦{formatPrice(total)}</span>
                  </p>
                </div>
                <button
                  type="button"
                  className="proceed-to-payment-btn"
                  onClick={() =>
                    isShippingFormValid() && setActiveSection('Payment')
                  }
                >
                  PROCEED TO PAYMENT
                </button>
              </div>
            )}
          </div>
        );
      case 'Payment':
        return (
          <div className="payment-section">
            <h2>Payment Details</h2>
            <PaystackIntegration
              amount={total}
              email={email}
              fullName={fullName}
              onSuccess={handlePaymentSuccess}
              onCancel={handlePaymentCancel}
            />
          </div>
        );
      case 'OrderConfirm':
        return (
          <div className="order-confirm-section">
            <OrderConfirm />
          </div>
        );
      default:
        return (
          <div className="dashboard-section">
            <h2>Welcome to your Dashboard!</h2>
            <p>Here you can manage your account settings and view your orders.</p>
          </div>
        );
    }
  };

  return (
    <div className="account-page">
      <div className="sidebar">
        {['Dashboard', 'Orders', 'Shipping', 'Payment'].map((section) => (
          <button
            key={section}
            className={`sidebar-btn ${
              activeSection === section ? 'active' : ''
            }`}
            onClick={() => setActiveSection(section)}
          >
            {section}
          </button>
        ))}
      </div>
      <div className="content-section">
        {renderContent()}
        {activeSection === 'Orders' && cartItems.length > 0 && (
          <div className="orders-panel" id="orderingsummary">
            <h3>Order Summary</h3>
            <div className="totals">
              <p className="totals-para">
                Subtotal: <span>₦{formatPrice(subtotal)}</span>
              </p>
              <p className="totals-para">
                Shipping Cost: <span>₦{formatPrice(shippingCost)}</span>
              </p>
              <p className="total">
                Total: <span>₦{formatPrice(total)}</span>
              </p>
            </div>
            <button
              className="checkout-btn"
              onClick={() => setActiveSection('Shipping')}
            >
              PROCEED
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Account;
