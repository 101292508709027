import React, { createContext, useContext, useState, useEffect } from 'react';

// Create the CartContext
const CartContext = createContext();

// Custom hook to use the CartContext
export const useCart = () => {
  return useContext(CartContext);
};

// CartProvider to manage the cart state and pass it down to components
export const CartProvider = ({ children }) => {
  // Initialize cartItems from local storage
  const [cartItems, setCartItems] = useState(() => {
    const savedItems = localStorage.getItem('cartItems');
    return savedItems ? JSON.parse(savedItems) : [];
  });

  // Add item to cart
  const addToCart = (item, quantity = 1) => {
    setCartItems((prevItems) => {
      const itemExists = prevItems.find((cartItem) => cartItem.id === item.id);
      if (itemExists) {
        return prevItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + quantity }
            : cartItem
        );
      }
      return [...prevItems, { ...item, quantity }];
    });
  };

  // Remove item from cart (decrease quantity if more than 1)
  const removeFromCart = (id) => {
    setCartItems((prevItems) => {
      const item = prevItems.find((item) => item.id === id);
      if (item && item.quantity > 1) {
        return prevItems.map((cartItem) =>
          cartItem.id === id ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem
        );
      }
      return prevItems.filter((item) => item.id !== id);
    });
  };

  // Clear the cart
  const clearCart = () => {
    setCartItems([]);
  };

  // Update local storage whenever cartItems changes
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
