import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import './OrderConfirm.css';

const OrderConfirm = () => {
  const location = useLocation();
  const {
    cartItems = [],
    shippingInfo = {},
    totalAmount = 0,
    reference = 'N/A',
    orderNumber = 'N/A',
  } = location.state || {};

  console.log({ cartItems, shippingInfo, totalAmount, reference, orderNumber });

  return (
    <div className="order-confirmation">
      {/* Header Section */}
      <div className="header-section">
        <h1>Thank You!</h1>
        <h2>Your order <span className="order-number">#{orderNumber}</span> has been placed!</h2>
        <p>
          We sent an email to <strong>{shippingInfo.email || 'N/A'}</strong> with your order confirmation and receipt.
          If the email hasn't arrived within two minutes, please check your spam folder.
        </p>
        <p className="time-placed">
          <strong>Time Placed:</strong> {new Date().toLocaleString()}
        </p>
      </div>

      {/* Order Information Section */}
      <div className="order-info-section">
        <div className="info-box">
          <h3>Shipping</h3>
          <p>{shippingInfo.fullName || 'N/A'}</p>
          <p>{shippingInfo.streetAddress || 'N/A'}</p>
          <p>{`${shippingInfo.city || 'N/A'}, ${shippingInfo.state || 'N/A'}, ${shippingInfo.countryRegion || 'N/A'}`}</p>
          <p>{shippingInfo.phone || 'N/A'}</p>
        </div>
        <div className="info-box">
          <h3>Billing Details</h3>
          <p>{shippingInfo.fullName || 'N/A'}</p>
          <p>{shippingInfo.streetAddress || 'N/A'}</p>
          <p>{`${shippingInfo.city || 'N/A'}, ${shippingInfo.state || 'N/A'}, ${shippingInfo.countryRegion || 'N/A'}`}</p>
        </div>
        <div className="info-box">
          <h3>Shipping Method</h3>
          <p>Preferred Method: U.S. Standard</p>
          <p>(Normally 4–5 business days, unless otherwise noted)</p>
        </div>
      </div>

      {/* Order List and Summary */}
      <div className="order-details-section">
        <div className="order-list">
          <h3>Order List</h3>
          <ul>
            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <li key={item.id} className="order-item">
                  <img src={item.image} alt={item.name} className="item-image" />
                  <div className="item-info">
                    <p className="item-name">{item.name}</p>
                    <p>#{item.id} | Qty: {item.quantity}</p>
                  </div>
                  <p className="item-price">₦{(item.price * item.quantity).toLocaleString()}</p>
                </li>
              ))
            ) : (
              <p>No items in the order.</p>
            )}
          </ul>
        </div>
        <div className="order-summary">
          <h3>Order Summary</h3>
          <p>
            <strong>Subtotal:</strong> ₦{cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0).toLocaleString()}
          </p>
          <p>
            <strong>Shipping & Handling:</strong> ₦5,000
          </p>
          <p>
            <strong>Est Sales Tax:</strong> ₦{(totalAmount * 0.1).toLocaleString()}
          </p>
          <p className="order-total">
            <strong>Total:</strong> ₦{totalAmount.toLocaleString()}
          </p>
        </div>
      </div>

      <p className="payment-reference">
        <strong>Payment Reference:</strong> {reference}
      </p>
    </div>
  );
};

export default OrderConfirm;
