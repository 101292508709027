import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './ProductModal.css';
import { useCart } from './CartContext'; // Adjust the path if needed

const ProductModal = ({ product, isOpen, onClose }) => {
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(product ? product.price : 0);
  const { addToCart } = useCart();

  useEffect(() => {
    if (product) {
      setTotalPrice(product.price * selectedQuantity);
    }
  }, [product, selectedQuantity]);

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  const handleAddToCart = () => {
    console.log('Selected Quantity:', selectedQuantity); // Debugging
    if (selectedSize && selectedColor && selectedQuantity > 0) {
      const item = {
        id: product.id,
        name: product.name,
        image: product.image,
        color: selectedColor,
        size: selectedSize,
        quantity: selectedQuantity,
        price: product.price,
      };

      addToCart(item);
      onClose();
    } else {
      alert('Please select size, color, and quantity before adding to the cart.');
    }
  };

  if (!product) return null;

  const formattedTotalPrice = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(totalPrice);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Product Modal" aria-modal="true">
      <div className="modal-content">
        <button className="close-button" onClick={onClose} aria-label="Close"></button>
        <h2>{product.name}</h2>
        <img src={product.image} alt={product.name} />
        
        <div className="color-group">
          <label>Color:</label>
          <div className="color-options">
            {['Red', 'Blue', 'Green', 'Black', 'Grey', 'Brown', 'Wine'].map((color) => (
              <button
                key={color}
                className={`color-option ${selectedColor === color.toLowerCase() ? 'selected' : ''}`}
                style={{ backgroundColor: color.toLowerCase() }}
                onClick={() => handleColorChange(color.toLowerCase())}
              ></button>
            ))}
          </div>
        </div>

        <div className="quantity-group">
          <label>Quantity:</label>
          <div className="quantity-controls">
            <button
              className="quantity-icon"
              onClick={() => setSelectedQuantity(Math.max(selectedQuantity - 1, 1))}
              disabled={selectedQuantity === 1}
            >
              -
            </button>
            <span className="quantity">{selectedQuantity}</span>
            <button
              className="quantity-icon"
              onClick={() => setSelectedQuantity(selectedQuantity + 1)}
            >
              +
            </button>
          </div>
        </div>

        <div className="size-group">
          <label>Size:</label>
          <div className="size-options">
            {[8, 9, 10, 11, 12].map((size) => (
              <button
                key={size}
                className={`size-option ${selectedSize === size ? 'selected' : ''}`}
                onClick={() => handleSizeSelect(size)}
              >
                {size}
              </button>
            ))}
          </div>
        </div>

        <div className="btm">
          <p className="total-price">Total Price: {formattedTotalPrice}</p>
          <button className="add-to-cart-button" onClick={handleAddToCart}>
            Add to Cart
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;
